.switcher {
    position: relative;

    overflow-y: hidden;

    margin-top: 40px;
    padding-bottom: 20px;
    
    .options {
        display: flex;
        justify-content: center;

        padding-bottom: 20px;
    }
}

.switcher::-webkit-scrollbar {
    width: 0;
}

@media (max-width: 1500px) {
    .switcher {

        .options {
            justify-content: start;
        }
    }
}
