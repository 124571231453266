.navbar {
    display: flex;

    .navbar-item {
        position: relative;

        margin: 0 20px;

        font-size: 28px;
        white-space: nowrap;

        cursor: pointer;
    }

    .navbar-item::after {
        content: '';

        width: 0;
        height: 3px;

        position: absolute;
        bottom: -5px;
        left: 0;

        background-color: #8A573C;
        border-radius: 10px;

        transition: all 0.5s;
    }

    .navbar-item:hover::after {
        width: 100%;
    }

    .active::after {
        content: '';
    
            width: 100%;
            height: 3px;
    
            position: absolute;
            bottom: -5px;
            left: 0;
    
            background-color: #8A573C;
            border-radius: 10px;
    
            transition: all 0.5s;
    }
}

@media (max-width: 1175px) {
    
    .navbar {
        display: none;
    }
}