.langs {
    display: flex;

    .lang {
        margin: 0 10px;

        border: none;
        background-color: white;

        cursor: pointer;
    }
}

@media (max-width: 600px) {
    .langs {
        position: absolute;
        left: 0;
    }
}